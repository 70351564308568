<template>
  <div class="content" v-if="offer">
    <block-content-11>
      <template #left>
        <div class="container-left content__container-left">
          <OfferNannyDetailsSectionTitle
            :title="offer.details.title"
            :languages="offer.requirements.languages"
            :activeness="offer.meta.actuality"
            :geo-label="offer.details.location.label"
          />
          <BlockTextQoute :text="readyTextQuote" />
          <BlockFee
            :fee-hour="offer.details.rate.hour"
            :fee-month="offer.details.rate.month"
          />
          <div class="section-description content__section-description">
            <BaseH2 :text="'Szczegóły'" />
            <BlockTextExpandableMain :text="offer.details.description" />
          </div>

          <div class="section-advantages content__section-advantages">
            <BaseH2 :text="'Oczekiwania wobec niani'" />
            <BaseGroupIconLabel
              :items="readyAdvantagesRequired"
              class="advantages section-advantages__advantages"
            />
          </div>
        </div>
      </template>

      <template #right>
        <div class="container-right content__container-right">
          <div class="wrapper-sticky container-right__wrapper-sticky">
            <div class="wrapper-sticky__photo-main-wrapper">
              <BaseTag
                v-if="isArchived"
                :text="'Ogłoszenie archiwalne'"
                class="photo-main-wrapper__archived-tag"
              />
              <BlockPhotoMain
                :src="offer.details.photoMain?.src"
                :default-color-num="offer.details.photoMainColorNum"
                @click="openGallery(0)"
                :class="[
                  'photo-main',
                  'wrapper-sticky__photo-main',
                  { 'is-archived': isArchived },
                ]"
              />
            </div>
            <PhotoGalleryDesktop
              v-if="!isArchived"
              ref="galleryRef"
              :photoMainImg="offer.details.photoMain"
              :photoGalleryImgs="[]"
              :defaultColorNum="offer.details.photoMainColorNum"
            />
            <BaseGroupIconLabel :items="offer.details.kids" />
            <div
              v-if="isContractor && !isArchived"
              class="buttons wrapper-sticky__buttons"
            >
              <ButtonCta @click="openPopupReply" :label="'Odpowiedź'" />
              <ButtonLink
                v-if="offer.details.phone?.length > 0"
                @click="openPopupCall"
                :label="'Zadzwon'"
              />
            </div>
          </div>
        </div>
      </template>
    </block-content-11>

    <OfferNannyDetailsSectionResponsibilities
      :child-care="offer.requirements.responsibilities.careChild"
      :activities="offer.requirements.responsibilities.activities"
      :home-care="offer.requirements.responsibilities.careHome"
    />

    <block-content-11>
      <template #left>
        <OfferNannyDetailsSectionSchedule
          :schedule-value="offer.schedule.value"
          :schedule-date-start-days-before-start="
            offer.schedule.dateStart.daysLeftBeforeStart
          "
          :schedule-date-start-string="offer.schedule.dateStart.string"
          :schedule-comment="offer.schedule.comment"
          :schedule-period="offer.schedule.period"
        />
      </template>

      <template #right>
        <div class="container-right content__container-right">
          <div
            class="wrapper-sticky container-right__wrapper-sticky container-right__wrapper-sticky--schedule"
          >
            <div
              v-if="isContractor"
              class="buttons wrapper-sticky__buttons wrapper-sticky__buttons--schedule"
            >
              <ButtonCta @click="openPopupReply" :label="'Odpowiedź'" />
              <ButtonLink
                v-if="offer.details.phone?.length > 0"
                @click="openPopupCall"
                :label="'Zadzwon'"
              />
            </div>
          </div>
        </div>
      </template>
    </block-content-11>

    <OfferNannyDetailsPopupReply
      v-if="canShowPopupReply"
      ref="popupReplyRef"
      :from-application-nanny-id="FROM_APPLICATION_ID"
      :on-offer-nanny-id="OFFER_ID"
    />

    <OfferNannyDetailsPopupCall
      v-if="canShowPopupCall"
      ref="popupCallRef"
      :parent-phone="offer.details.phone"
      :offer-id="OFFER_ID"
    />

    <PopupPushToRegistration
      v-if="isAuth === false"
      ref="popupPushToRegistrationRef"
      :description="'I wypełnij profil aby odpowiedzieć na tę ofertę'"
    />

    <OfferNannyPopupPushToCreateApplication
      v-if="isAuth"
      ref="popupPushToCreateApplicationRef"
    />
  </div>
</template>

<script>
import useOfferNannyDetails from "@/composables/useOfferNannyDetails";
import useAuth from "@/composables/useAuth";

import BlockTextExpandableMain from "@/components/BlockTextExpandableMain.vue";
import BaseGroupIconLabel from "@/components/UI/Base/BaseGroupIconLabel.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";
import OfferNannyDetailsSectionTitle from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionTitle.vue";
import BlockTextQoute from "@/components/BlockTextQoute.vue";
import BlockFee from "@/components/BlockFee.vue";
import BlockContent11 from "@/layouts/BlockContent11.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BlockPhotoMain from "@/components/BlockPhotoMain.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import OfferNannyDetailsSectionResponsibilities from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionResponsibilities.vue";
import OfferNannyDetailsSectionSchedule from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionSchedule.vue";
import PhotoGalleryDesktop from "@/components/PhotoGalleryDesktop.vue";
import OfferNannyDetailsPopupReply from "@/components/Offer/Nanny/Details/OfferNannyDetailsPopupReply.vue";
import OfferNannyDetailsPopupCall from "@/components/Offer/Nanny/Details/OfferNannyDetailsPopupCall.vue";
import PopupPushToRegistration from "@/components/PopupPushToRegistration.vue";
import OfferNannyPopupPushToCreateApplication from "@/components/Offer/Nanny/OfferNannyPopupPushToCreateApplication.vue";
import BaseTag from "@/components/UI/Base/BaseTag.vue";

export default {
  components: {
    BlockTextExpandableMain,
    BaseGroupIconLabel,
    ButtonCta,
    OfferNannyDetailsSectionTitle,
    BlockTextQoute,
    BlockFee,
    BlockContent11,
    BaseH2,
    BlockPhotoMain,
    ButtonLink,
    OfferNannyDetailsSectionResponsibilities,
    OfferNannyDetailsSectionSchedule,
    PhotoGalleryDesktop,
    OfferNannyDetailsPopupReply,
    OfferNannyDetailsPopupCall,
    PopupPushToRegistration,
    OfferNannyPopupPushToCreateApplication,
    BaseTag,
  },

  setup() {
    const { isAuth } = useAuth();

    const {
      popupPushToRegistrationRef,
      popupPushToCreateApplicationRef,

      popupReplyRef,
      openPopupReply,

      popupCallRef,
      openPopupCall,

      galleryRef,
      openGallery,

      offer,
      readyTextQuote,
      readyAdvantagesRequired,
      isContractor,
      isArchived,
      canShowPopupReply,
      canShowPopupCall,

      OFFER_ID,
      FROM_APPLICATION_ID,
    } = useOfferNannyDetails();

    return {
      isAuth,
      isContractor,
      isArchived,
      OFFER_ID,
      FROM_APPLICATION_ID,
      galleryRef,
      openGallery,
      popupReplyRef,
      openPopupReply,
      popupCallRef,
      openPopupCall,
      offer,
      readyTextQuote,
      readyAdvantagesRequired,
      canShowPopupReply,
      canShowPopupCall,
      popupPushToRegistrationRef,
      popupPushToCreateApplicationRef,
    };
  },
};
</script>

<style scoped>
.content {
  padding: 100px 0;
  display: flex;
  flex-flow: column;
  align-items: center;

  row-gap: 100px;
}
.content__container-left {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 100px;
}
.content__container-right {
  height: 100%;
}
.container-right__wrapper-sticky {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 40px;

  position: sticky;
  top: 100px;
}
.container-right__wrapper-sticky--schedule {
  top: calc((100vh - 117px) / 2 - 66px - 15px);
}
.wrapper-sticky__photo-main-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}
.photo-main-wrapper__archived-tag {
  position: absolute;
  top: calc(var(--tag-height) / -2);
}
.wrapper-sticky__photo-main.is-archived {
  opacity: 0.5;
  pointer-events: none;
}
.wrapper-sticky__buttons {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 30px;
}
.wrapper-sticky__buttons--schedule {
  margin-top: calc(66px + 15px);
}
.content__section-work-exp-edu {
  width: 100%;

  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  column-gap: 40px;
}
.container-right__wrapper-review-sticky {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;

  position: sticky;
  top: calc((100vh - 107px - 47px) / 2);
}

.content__section-description {
  display: flex;
  flex-flow: column;
  row-gap: 25px;
}
.content__section-advantages {
  display: flex;
  flex-flow: column;
  row-gap: 25px;
}
.content__section-advantages:deep(.section-advantages__advantages) {
  justify-content: flex-start;
}
</style>
