import offerNannyResponsesApi from "@/api/offerNannyResponses";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const offerNannyResponsesService = {
  async create(payload) {
    const data = await offerNannyResponsesApi.createOne(payload);
    await userStore.refetchUserStore();
    return data;
  },
  async getOne() {},
  async getMany(payload) {
    const data = await offerNannyResponsesApi.getMany(payload);
    return data;
  },
  async updateOne(id, payload) {
    const data = await offerNannyResponsesApi.updateOne(id, payload);
    return data;
  },
  async delete() {},
};

export default offerNannyResponsesService;
