<template>
  <div class="section-perks">
    <BaseH2 :text="'Oczekiwania wobec niani'" />

    <BaseGroupIconLabel
      :items="readyItems"
      class="perks section-perks__perks"
    />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import BaseGroupIconLabel from "@/components/UI/Base/BaseGroupIconLabel.vue";
import SvgIconsLanguage24 from "@/components/Svg/Icons/SvgIconsLanguage24.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";

export default {
  components: { BaseGroupIconLabel, BaseH2 },

  props: {
    perks: Array,
    languages: Array,
  },

  setup(props) {
    const readyItems = computed(() => {
      let languagesString = props.languages.reduce(
        (result, language, index) => {
          if (index === 1) {
            result = result.label;
          }
          return result + ", " + language.label;
        }
      );

      return [
        {
          slug: "languages",
          label: "Znajomość języków: " + languagesString,
          iconComponent: SvgIconsLanguage24,
        },
        ...props.perks,
      ];
    });
    return { readyItems };
  },
};
</script>

<style scoped>
.section-perks {
  width: 100%;

  padding: 30px;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;

  background-color: #e5eaf9;
  border-radius: 10px;
}
.section-perks .section-perks__perks {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
</style>
