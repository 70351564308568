<template>
  <BasePopup ref="popupRef" @opened="handleOpened" @closed="handleClosed">
    <div v-if="mode === MODES.SUCCESS" class="popup-content">
      <BaseH1 :text="'Zadzwoń do rodzica'" />
      <TextDescription :text="`( ${parentName} )`" />
      <ButtonLink
        @click="handlePhoneClick"
        :label="parentPhone"
        class="popup-content__phone"
      />
    </div>

    <div
      v-else-if="mode === MODES.ERROR"
      class="popup-content popup-content--error"
    >
      <BaseH1 :text="'Error...'" />
      <TextHint :text="'Please, try again'" />
    </div>

    <div
      v-else-if="mode === MODES.LOADING"
      class="popup-content popup-content--loading"
    >
      <BaseH1 :text="'Proszę czekać...'" :short="true" />
      <BaseSpinner class="spinner" />
    </div>
  </BasePopup>
</template>

<script>
import { reactive, ref, toRefs } from "vue";

import BasePopup from "@/components/BasePopup.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import BaseSpinner from "@/components/UI/Base/BaseSpinner.vue";
import offerNannyService from "@/services/offerNanny";
import TextHint from "@/components/UI/TextHint.vue";

const MODES = {
  LOADING: "LOADING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

const getDefaultState = () => {
  return {
    isLoading: false,
    parentName: "",
    mode: MODES.LOADING,
  };
};

export default {
  components: {
    BasePopup,
    BaseH1,
    TextDescription,
    ButtonLink,
    BaseSpinner,
    TextHint,
  },

  props: {
    offerId: String,
    parentPhone: String,
  },

  expose: ["open", "close"],

  setup(props) {
    const popupRef = ref(null);

    const state = reactive(getDefaultState());

    const open = () => {
      if (popupRef.value === null) {
        return;
      }
      popupRef.value.open();
    };

    const close = () => {
      if (popupRef.value === null) {
        return;
      }
      popupRef.value.close();
    };

    const handleOpened = async () => {
      // Initialize popup data
      try {
        state.isLoading = true;
        const profile = await offerNannyService.getUserProfile(props.offerId);
        state.parentName = profile.nameFirst;
        state.mode = MODES.SUCCESS;
      } catch (error) {
        state.mode = MODES.ERROR;
        alert(error.message);
      } finally {
        state.isLoading = false;
      }
    };

    const handleClosed = () => {
      // Reset state to default
      Object.assign(state, getDefaultState());
    };

    const handlePhoneClick = () => {
      // TODO: rewrite with a regex (to replace '-' and any other odd symbols)
      window.open(`tel:${props.parentPhone.replaceAll(" ", "")}`);
    };

    return {
      ...toRefs(state),
      MODES,
      popupRef,
      handleOpened,
      handleClosed,
      handlePhoneClick,
      open,
      close,
    };
  },
};
</script>

<style scoped>
.popup-content {
  margin: auto;
  padding: 0px 60px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 40px;
}
.popup-content--error {
  row-gap: 120px;
}
.popup-content--loading {
  row-gap: 120px;
}
.popup-content .popup-content__phone {
  font-size: 26px;
}

@media (max-width: 1200px) {
  .popup-content {
    width: var(--layout-default--width);
    margin: auto;
    padding: 0px;
    display: flex;
    flex-flow: column;
    row-gap: 50px;
  }
  .popup-content--error {
    row-gap: 80px;
  }
  .popup-content--loading {
    row-gap: 80px;
  }
}
</style>
