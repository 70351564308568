<template>
  <div class="section-description">
    <BaseH2 v-html="'Szczegóły'" />
    <BlockTextExpandableMain :text="description" />
  </div>
</template>

<script>
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BlockTextExpandableMain from "@/components/BlockTextExpandableMain.vue";

export default {
  components: { BaseH2, BlockTextExpandableMain },

  props: {
    description: String,
  },
};
</script>

<style scoped>
.section-description {
  width: 100%;

  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
</style>
