import gql from "graphql-tag";

export const CREATE_OFFER_NANNY_RESPONSE = gql`
  mutation CreateOfferNannyResponse($payload: CreateOfferNannyResponseInput!) {
    createOfferNannyResponse(payload: $payload) {
      _id
    }
  }
`;

export const UPDATE_OFFER_NANNY_RESPONSE = gql`
  mutation UpdateOfferNannyResponse(
    $id: ID!
    $payload: UpdateOfferNannyResponseInput!
  ) {
    updateOfferNannyResponse(_id: $id, payload: $payload) {
      _id
    }
  }
`;

export const DELETE_OFFER_NANNY_RESPONSE = gql`
  mutation DeleteOfferNannyResponse($id: ID!) {
    deleteOfferNannyResponse(_id: $id) {
      _id
    }
  }
`;
