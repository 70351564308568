<template>
  <div class="section-responsibilities">
    <BaseH2 v-html="'Obowiazki'" />
    <BlockContent11>
      <template #left>
        <BlockGroupChips
          :block-title="'Opieka nad dzieckiem'"
          :items="childCare"
          :items-crossed="crossedChildCare"
        />
      </template>
      <template #right>
        <BlockGroupChips :block-title="'Zajęcia'" :items="readyActivities" />
      </template>
    </BlockContent11>
    <div class="block-care-home section-responsibilities__block-care-home">
      <BaseH3 :text="'Pomoc domowa'" />
      <BaseGroupIconLabelRound :items="readyHomeCareItems" />
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import useOfferNannyDictionary from "@/composables/useOfferNannyDictionary";

import BlockGroupChips from "@/components/BlockGroupChips.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BlockContent11 from "@/layouts/BlockContent11.vue";
import BaseH3 from "@/components/UI/Base/BaseH3.vue";
import BaseGroupIconLabelRound from "@/components/UI/Base/BaseGroupIconLabelRound.vue";

export default {
  components: {
    BlockGroupChips,
    BaseH2,
    BlockContent11,
    BaseH3,
    BaseGroupIconLabelRound,
  },

  props: {
    childCare: Array,
    activities: Array,
    homeCare: Array,
  },

  setup(props) {
    const { DICTIONARY } = useOfferNannyDictionary();

    const readyActivities = computed(() => {
      let activities = [];
      if (props.activities.length) {
        props.activities.forEach((activity) => {
          activities.push({
            slug: activity.slug,
            label: activity.labelShort,
          });
        });
      }

      return activities;
    });

    const crossedChildCare = computed(() => {
      const childCareItems = props.childCare;
      const notIncludedEntries = [];

      if (childCareItems) {
        let allChildCareSlugs = Object.keys(
          DICTIONARY.responsibilities.childCare
        );
        for (let slug of allChildCareSlugs) {
          if (!childCareItems.some((item) => item.slug === slug)) {
            notIncludedEntries.push(
              DICTIONARY.responsibilities.childCare[slug]
            );
          }
        }
      }

      return notIncludedEntries;
    });

    const HOME_CARE_DICT = DICTIONARY.responsibilities.homeCare;

    const readyHomeCareItems = computed(() => {
      const homeCareItems = props.homeCare;
      const result = [];

      if (homeCareItems) {
        let allHomeCareSlugs = Object.keys(HOME_CARE_DICT);
        allHomeCareSlugs.forEach((slug) => {
          const readyItem = {
            ...HOME_CARE_DICT[slug],
            isPresent: homeCareItems.some((item) => item.slug === slug),
          };
          result.push(readyItem);
        });
      }

      return result;
    });

    return { readyActivities, crossedChildCare, readyHomeCareItems };
  },
};
</script>

<style scoped>
.section-responsibilities {
  padding: 50px 70px 70px 70px;
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 50px;

  background: #f5faff;
  border-radius: 10px;
}
.section-responsibilities__block-care-home {
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
</style>
