<template>
  <div class="section-shedule">
    <div class="wrapper-title section-shedule__wrapper-title">
      <BaseH2 v-html="'Grafik'" />
      <BaseText v-html="readyDateStartText" />
    </div>
    <Schedule :schedule-value="scheduleValue" />
    <BaseText v-html="readyPeriodText" />

    <BlockTextExpandableComment :text="scheduleComment" :max-length="200" />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import Schedule from "@/components/Schedule.vue";
import BlockTextExpandableComment from "@/components/BlockTextExpandableComment.vue";

export default {
  components: {
    BaseH2,
    BaseText,
    Schedule,
    BlockTextExpandableComment,
  },

  props: {
    scheduleDateStartDaysBeforeStart: Number,
    scheduleDateStartString: String,
    scheduleValue: Array,
    scheduleComment: String,
    schedulePeriod: Object,
  },

  setup(props) {
    const readyDateStartText = computed(() => {
      const daysBeforeStart = props.scheduleDateStartDaysBeforeStart;
      const dateStartString = props.scheduleDateStartString;

      if (typeof daysBeforeStart !== "number") {
        throw Error(
          "daysBeforeStart is not a Numeber! Type of daysBeforeStart: " +
            typeof daysBeforeStart
        );
      }

      if (daysBeforeStart > 0) {
        return `Start od ${dateStartString}, przez ${daysBeforeStart} dni`;
      } else {
        return `Start ${dateStartString}`;
      }
    });

    const readyPeriodText = computed(() => {
      let periodTerm = props.schedulePeriod.term;
      let periodAmount = props.schedulePeriod.amount;
      let periodMeasure = props.schedulePeriod.measure;

      let periodText = "";

      if (periodTerm && periodTerm.slug === "custom") {
        periodText = `Na ${periodAmount} ${periodMeasure.label.toLowerCase()}`;
      } else if (periodTerm && periodTerm.slug === "long_term") {
        periodText = `Na ${periodTerm.label} okres`;
      }

      return periodText;
    });

    return { readyDateStartText, readyPeriodText };
  },
};
</script>

<style scoped>
.section-shedule {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 20px;
}

.section-shedule__wrapper-title {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  /* row-gap: 10px; */
}
</style>
