<template>
  <BasePopup ref="popupRef" @opened="handleOpened" @closed="handleClosed">
    <div v-if="mode === MODES.SEND_REPLY" class="popup-content">
      <BaseH1 :text="'Wyślij zgłoszenie'" />
      <InputTextTextarea
        v-model="replyMessage"
        :name="'reply-message'"
        :placeholder="PLACEHOLDER"
        :rows="4"
      />
      <TextHint :text="HINT" />
      <ButtonCtaDone @click="handleDone" />
    </div>

    <div
      v-else-if="mode === MODES.ALREADY_SENT"
      class="popup-content popup-content--already-sent"
    >
      <BaseH1 :text="'You have already replied'" />
      <TextDescription :text="'Please, wait for the response from parents'" />
    </div>

    <div
      v-else-if="mode === MODES.SUCCESS"
      class="popup-content popup-content--success"
    >
      <BaseH1 :text="'Zgłoszenie zostało wysłane'" />
      <TextDescription :text="'Trzymamy kciuki za Ciebe!'" />
      <SvgIllustrationsTick class="popup-content--success__tick" />
    </div>

    <div
      v-else-if="mode === MODES.ERROR"
      class="popup-content popup-content--error"
    >
      <BaseH1 :text="'Error...'" />
      <TextHint :text="'Please, try again'" />
    </div>

    <div
      v-else-if="mode === MODES.LOADING"
      class="popup-content popup-content--loading"
    >
      <BaseH1 :text="'Proszę czekać...'" :short="true" />
      <BaseSpinner class="spinner" />
    </div>
  </BasePopup>
</template>

<script>
import { reactive, ref, toRefs } from "vue";
import offerNannyResponsesService from "@/services/offerNannyResponses";

import BasePopup from "@/components/BasePopup.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputTextTextarea from "@/components/UI/Base/InputTextTextarea.vue";
import TextHint from "@/components/UI/TextHint.vue";
import ButtonCtaDone from "@/components/UI/ButtonCtaDone.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import SvgIllustrationsTick from "@/components/Svg/Illustrations/SvgIllustrationsTick.vue";
import BaseSpinner from "@/components/UI/Base/BaseSpinner.vue";

const PLACEHOLDER =
  "NP: Dzień dobry, nazywam się Aneta, jestem doświadczoną opiekunką i chętnie zaopekuję się Pani maluchem:) Pozdrawiam serdecznie";
const HINT = "Wiadomość towarzysząca dla rodziców jest opcjonalna";

const MODES = {
  LOADING: "LOADING",
  SEND_REPLY: "SEND_REPLY",
  ALREADY_SENT: "ALREADY_SENT",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const getDefaultState = () => {
  return {
    replyMessage: "",
    isLoading: false,
    isSent: false,
    mode: MODES.LOADING,
  };
};

export default {
  components: {
    BasePopup,
    BaseH1,
    InputTextTextarea,
    TextHint,
    ButtonCtaDone,
    TextDescription,
    SvgIllustrationsTick,
    BaseSpinner,
  },

  props: {
    fromApplicationNannyId: String,
    onOfferNannyId: String,
  },

  expose: ["open", "close"],

  setup(props) {
    const popupRef = ref(null);

    const state = reactive(getDefaultState());

    const open = () => {
      if (popupRef.value === null) {
        return;
      }
      popupRef.value.open();
    };

    const close = () => {
      if (popupRef.value === null) {
        return;
      }
      popupRef.value.close();
    };

    const handleDone = async () => {
      await _trySendReply();
    };

    const handleOpened = async () => {
      // Initialize popup data
      const isSent = await _tryCheckIfAlreadySent();
      state.isSent = isSent;
      if (isSent) {
        state.mode = MODES.ALREADY_SENT;
      } else {
        state.mode = MODES.SEND_REPLY;
      }
    };

    const handleClosed = () => {
      // Reset state to default
      Object.assign(state, getDefaultState());
    };

    const _tryCheckIfAlreadySent = async () => {
      try {
        const payload = {
          filters: {
            from_application_nanny_id: props.fromApplicationNannyId,
            on_offer_nanny_id: props.onOfferNannyId,
          },
        };
        const responses = await offerNannyResponsesService.getMany(payload);
        console.log({ responses });
        const isSent = responses.length > 0;
        return isSent;
      } catch (error) {
        alert(error.message);
      }
    };

    const _trySendReply = async () => {
      try {
        if (state.isSent || state.isLoading) {
          return;
        }
        state.isLoading = true;
        state.mode = MODES.LOADING;
        const payload = {
          from_application_nanny_id: props.fromApplicationNannyId,
          on_offer_nanny_id: props.onOfferNannyId,
          message: state.replyMessage,
        };
        await offerNannyResponsesService.create(payload);
        state.isSent = true;
        state.mode = MODES.SUCCESS;
      } catch (error) {
        state.mode = MODES.ERROR;
        alert(error.message);
      } finally {
        state.isLoading = false;
      }
    };

    return {
      ...toRefs(state),
      MODES,
      PLACEHOLDER,
      HINT,
      popupRef,
      handleDone,
      handleOpened,
      handleClosed,
      open,
      close,
    };
  },
};
</script>

<style scoped>
.popup-content {
  margin: auto;
  padding: 0px 60px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 40px;
}
.popup-content--already-sent {
  row-gap: 120px;
}
.popup-content--error {
  row-gap: 120px;
}
.popup-content--success {
  row-gap: 60px;
}
.popup-content--success__tick {
  width: 124px;
  height: 115px;
}
.popup-content--loading {
  row-gap: 120px;
}
.spinner {
  width: 90px;
  height: 90px;
}

@media (max-width: 1200px) {
  .popup-content {
    width: var(--layout-default--width);
    margin: auto;
    padding: 0px;
    display: flex;
    flex-flow: column;
    row-gap: 50px;
  }
  .popup-content--success__tick {
    width: unset;
    height: unset;
  }
  .popup-content--already-sent {
    row-gap: 80px;
  }
  .popup-content--error {
    row-gap: 80px;
  }
  .popup-content--loading {
    row-gap: 80px;
  }
}
</style>
