<template>
  <component :is="offerNannyDetailsComponent"> </component>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { onMounted } from "vue";

import useIsMobile from "@/composables/useIsMobile";
import useAnonymusAdGuard from "@/composables/useAnonymusAdGuard";

import OfferNannyDetailsDesktop from "@/views/Offer/Nanny/Details/OfferNannyDetailsDesktop.vue";
import OfferNannyDetailsMobile from "@/views/Offer/Nanny/Details/OfferNannyDetailsMobile.vue";

export default {
  setup() {
    const { isMobile } = useIsMobile();

    const offerNannyDetailsComponent = computed(() => {
      if (isMobile.value) return OfferNannyDetailsMobile;
      return OfferNannyDetailsDesktop;
    });

    const { countAdClick } = useAnonymusAdGuard(ref("offer"), ref("nanny"));

    onMounted(() => {
      countAdClick();
    });

    return { offerNannyDetailsComponent };
  },
};
</script>
