<template>
  <BasePopup ref="popupRef">
    <div class="popup-content">
      <BaseH1 :text="'Wypełnij swój profil'" />
      <TextDescription
        :text="'Aby zgłosić się na tą ofertę, musisz mieć wypełniony profil. To proste!'"
      />
      <ButtonCta
        :to="ROUTE_APPLICATION_NANNY_CREATE"
        :label="'Wypełnij profil'"
      />
    </div>
  </BasePopup>
</template>

<script>
import { ref } from "vue";
import { ROUTE_APPLICATION_NANNY_CREATE_NAME } from "@/router/constants";

import BasePopup from "@/components/BasePopup.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";

export default {
  components: {
    BasePopup,
    BaseH1,
    TextDescription,
    ButtonCta,
  },

  expose: ["open", "close"],

  setup() {
    const popupRef = ref(null);

    const ROUTE_APPLICATION_NANNY_CREATE = {
      name: ROUTE_APPLICATION_NANNY_CREATE_NAME,
    };

    const open = () => {
      if (popupRef.value === null) return;
      popupRef.value.open();
    };

    const close = () => {
      if (popupRef.value === null) return;
      popupRef.value.close();
    };

    return {
      popupRef,
      ROUTE_APPLICATION_NANNY_CREATE,
      open,
      close,
    };
  },
};
</script>

<style scoped>
.popup-content {
  margin: auto;
  padding: 0px 60px;
  display: flex;
  flex-flow: column;
  row-gap: 65px;
}

@media (max-width: 1200px) {
  .popup-content {
    margin: auto;
    padding: 0px 30px;
    display: flex;
    flex-flow: column;
    row-gap: 50px;
  }
}
</style>
