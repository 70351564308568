<template>
  <div class="spinner" />
</template>

<style scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner {
  min-width: 24px;
  min-height: 24px;
  background: url(~@/assets/images/icons/spinner.png) center / cover no-repeat;
  animation: spin 2s infinite linear;
}
</style>
