<template>
  <div class="section-kids">
    <div class="container-kids section-kids__container-kids">
      <span class="container-kids__prefix">Dla</span>
      <BaseGroupIconLabel :items="kids" class="kids container-kids__kids" />
    </div>
    <BaseText
      v-if="isSpecialCare"
      :v-html="`<span>!</span> ${specialCareLabel}`"
      :is-small="true"
      class="special-care section-kids__special-care"
    />
  </div>
</template>

<script>
import useOfferNannyDictionary from "@/composables/useOfferNannyDictionary";

import BaseGroupIconLabel from "@/components/UI/Base/BaseGroupIconLabel.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";

export default {
  components: { BaseGroupIconLabel, BaseText },

  props: {
    kids: Array,
    isSpecialCare: Boolean,
  },

  setup() {
    const { DICTIONARY } = useOfferNannyDictionary();
    const specialCareLabel = DICTIONARY["special_care"].labelShort;

    return { specialCareLabel };
  },
};
</script>

<style scoped>
.section-kids {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 5px;
}
.section-kids__container-kids {
  width: 100%;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
}
.container-kids .container-kids__kids {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  row-gap: 10px;
  column-gap: 20px;
}
</style>
