import {
  CREATE_OFFER_NANNY_RESPONSE,
  DELETE_OFFER_NANNY_RESPONSE,
  UPDATE_OFFER_NANNY_RESPONSE,
} from "@/graphql/mutations/offerNannyResponse";
import {
  GET_OFFER_NANNY_RESPONSE,
  GET_OFFER_NANNY_RESPONSES,
} from "@/graphql/queries/offerNannyResponse";
import { apolloClient } from "@/plugins/apollo";

const offerNannyResponsesApi = {
  async createOne(payload) {
    if (!payload) {
      throw new Error("No payload provided!");
    }

    const { data } = await apolloClient.mutate({
      mutation: CREATE_OFFER_NANNY_RESPONSE,
      variables: {
        payload,
      },
    });

    return data.createOfferNannyResponse;
  },

  async getOne(id) {
    if (!id) {
      throw new Error("No id provided!");
    }

    const { data } = await apolloClient.query({
      query: GET_OFFER_NANNY_RESPONSE,
      variables: {
        id,
      },
      fetchPolicy: "network-only",
    });

    return data.offerNannyResponse;
  },

  async getMany({ ids, populatePath, populate, filters }) {
    const { data } = await apolloClient.query({
      query: GET_OFFER_NANNY_RESPONSES,
      variables: {
        ids,
        populatePath,
        populate,
        filters,
      },
      fetchPolicy: "network-only",
    });

    return JSON.parse(JSON.stringify(data.offerNannyResponses));
  },

  async updateOne(id, payload) {
    if (!id) {
      throw new Error("No id provided!");
    }
    if (!payload) {
      throw new Error("No payload provided!");
    }

    const { data } = await apolloClient.mutate({
      mutation: UPDATE_OFFER_NANNY_RESPONSE,
      variables: {
        id,
        payload,
      },
    });

    return data.updateOfferNannyResponse;
  },

  async deleteOne(id) {
    if (!id) {
      throw new Error("No id provided!");
    }

    const { data } = await apolloClient.mutate({
      mutation: DELETE_OFFER_NANNY_RESPONSE,
      variables: {
        id,
      },
    });

    return data.deleteOfferNannyResponse;
  },
};

export default offerNannyResponsesApi;
