<template>
  <div class="section-photo">
    <div
      v-if="!isArchived"
      class="activeness section-photo__activeness"
      :class="`activeness--${activeness.slug}`"
    >
      <span class="icon activeness__icon"></span>
      <label class="label activeness__label">{{ activeness.label }}</label>
    </div>

    <div class="section-photo__photo-main-wrapper">
      <BaseTag
        v-if="isArchived"
        :text="'Ogłoszenie archiwalne'"
        class="section-photo__archived-tag"
      />
      <BlockPhotoMain
        :src="photoMain?.src"
        :default-color-num="defaultColorNum"
        :class="[
          'photo-main',
          'section-photo__photo-main',
          { 'is-archived': isArchived },
        ]"
      />
    </div>
  </div>
</template>

<script>
import BlockPhotoMain from "@/components/BlockPhotoMain.vue";
import BaseTag from "@/components/UI/Base/BaseTag.vue";

export default {
  components: {
    BlockPhotoMain,
    BaseTag,
  },

  props: {
    activeness: Object,
    photoMain: Object,
    defaultColorNum: Number,
    isArchived: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.section-photo {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
}
.section-photo__photo-main-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}
.section-photo__archived-tag {
  position: absolute;
  top: calc(var(--tag-height) / -2);
}
.section-photo__photo-main.is-archived {
  opacity: 0.5;
  pointer-events: none;
}
.section-photo__activeness {
  margin: 5px auto;

  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 9px;

  font: var(--system-font);
}
.activeness__icon {
  width: 10px;
  height: 10px;
  margin-top: 3px;

  border-radius: 5px;
}
.activeness--actual .activeness__label {
  color: rgba(22, 147, 155, 0.8);
}
.activeness--actual .activeness__icon {
  background-color: #72f15d;
}
.activeness--outdated .activeness__label {
  color: #fe881c;
}
.activeness--outdated .activeness__icon {
  background-color: #fe881c;
}
</style>
