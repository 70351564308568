<template>
  <svg
    width="98"
    height="91"
    viewBox="0 0 98 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="svg-illustration"
  >
    <path
      d="M97.5858 12.8474C100.532 7.85528 86.9801 -2.41704 81.6772 0.518103C79.0574 1.96816 67.6477 17.3576 56.7726 34.6387C47.3434 49.6222 39.1606 65.7455 36.8721 70.3395C36.5612 70.9635 35.7247 71.0904 35.2412 70.5882C30.7575 65.9303 12.4816 47.1431 8.60673 46.3101C4.18184 45.359 -1.48814 53.3553 0.358561 56.5843C1.25546 58.1526 12.6004 68.9839 19.4438 76.029C31.3636 88.3001 29.3766 91 37.7092 91C47.1366 91 44.4745 81.0193 70.4778 46.3101C81.1675 32.0415 94.6397 17.8395 97.5858 12.8474Z"
      fill="#70A46F"
    />
  </svg>
</template>
