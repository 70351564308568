import useAuth from "@/composables/useAuth";
import { VISIT_OFFER_NANNY } from "@/graphql/mutations/offerNanny";
import { GET_OFFER_NANNY } from "@/graphql/queries/offerNanny";
import { prepareOfferNannyInstance } from "@/helpers";
import { useUserStore } from "@/stores/user";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { ref, computed } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import useOfferNannyDictionary from "./useOfferNannyDictionary";

export default function useOfferNannyDetails() {
  const { isAuth } = useAuth();
  const userStore = useUserStore();
  const route = useRoute();

  const popupPushToRegistrationRef = ref(null);
  const popupPushToCreateApplicationRef = ref(null);

  const popupReplyRef = ref(null);
  const popupCallRef = ref(null);
  const galleryRef = ref(null);
  const offer = ref(null);

  const OFFER_ID = route.params.id;
  const FROM_APPLICATION_ID = userStore.applications.nanny_id || null;
  const { DICTIONARY } = useOfferNannyDictionary();

  const readyAdvantagesRequired = computed(() => {
    if (offer.value === null) {
      return [];
    }
    const advReq = [...offer.value.requirements.perks];
    offer.value.details.isSpecialCare
      ? advReq.push(DICTIONARY["special_care"])
      : "";

    return advReq;
  });

  const readyTextQuote = computed(() => {
    if (offer.value === null) {
      return "";
    }

    let text = "";

    const aupair = offer.value.details.aupair;
    const aupairText = `Szukam niani <b>${aupair.label}</b>.`;

    const employmentType = offer.value.employment.type;
    const employmentRegularity = offer.value.employment.regularity;
    let employmentText;
    switch (employmentType.slug) {
      case "baby_sitting":
        employmentText = `<b>${employmentType.label}</b>.`;
        break;
      case "full_time":
        employmentText = `Na <b>${employmentType.label}</b>.`;
        break;
      case "part_time":
        employmentText = `Na <b>${employmentType.label}</b>. ${employmentRegularity.label}.`;
        break;

      default:
        break;
    }

    const scheduleStart = offer.value.schedule.dateStart.label;
    const schedulePeriodTerm = offer.value.schedule.period.term;
    let schedulePeriodText = "";
    if (schedulePeriodTerm.slug === "custom") {
      schedulePeriodText = `${offer.value.schedule.period.amount} ${offer.value.schedule.period.measure.label}`;
    } else if (schedulePeriodTerm.slug === "long_term") {
      schedulePeriodText = `${schedulePeriodTerm.label} okres`;
    }

    const scheduleText = `Start ${scheduleStart} na ${schedulePeriodText}.`;

    text = `${aupairText}<br />
      ${employmentText}<br />
      ${scheduleText}`;

    return text;
  });

  const isContractor = computed(() => {
    return userStore.userType === "contractor";
  });

  const isArchived = computed(() => {
    if (!offer.value) {
      return false;
    }
    return offer.value.meta.status === "archived";
  });

  const canShowPopupReply = computed(() => {
    return isAuth.value && isContractor.value && _hasNannyApplication();
  });

  const canShowPopupCall = computed(() => {
    return (
      isAuth.value &&
      isContractor.value &&
      offer.value?.details.phone?.length > 0 &&
      _hasNannyApplication()
    );
  });

  onMounted(() => {
    const { mutate: updateVisits } = useMutation(VISIT_OFFER_NANNY);

    initOffer().then((offer) => {
      const variables = getVisitMutationVariables(offer);
      updateVisits(variables);
    });
  });

  const initOffer = async () => {
    return new Promise((resolve, reject) => {
      const { onResult, onError } = useQuery(
        GET_OFFER_NANNY,
        () => ({
          id: OFFER_ID,
        }),
        () => ({
          fetchPolicy: "cache-and-network",
        })
      );

      onResult((result) => {
        const offerNannyRaw = result.data.offerNanny;

        if (offerNannyRaw === null) return;

        offer.value = prepareOfferNannyInstance(offerNannyRaw);

        resolve(offerNannyRaw);
      });

      onError((error) => reject(error.message));
    });
  };

  const getVisitMutationVariables = (offer) => {
    const variables = { id: offer._id };

    const user = useUserStore();
    const user_id = user._id;
    const isAuth = user.isAuth;
    if (
      isAuth &&
      user_id &&
      offer.meta.visitor_user_ids?.includes(user_id) === false
    ) {
      Object.assign(variables, { visitorUserId: user_id });
    }

    return variables;
  };

  const openPopupReply = () => {
    if (isAuth.value === false && popupPushToRegistrationRef.value) {
      return popupPushToRegistrationRef.value.open();
    }

    if (
      _hasNannyApplication() === false &&
      popupPushToCreateApplicationRef.value
    ) {
      // open popup-push to Create application
      return popupPushToCreateApplicationRef.value.open();
    }

    if (canShowPopupReply.value && popupReplyRef.value) {
      return popupReplyRef.value.open();
    }
  };

  const openPopupCall = () => {
    if (isAuth.value === false && popupPushToRegistrationRef.value) {
      return popupPushToRegistrationRef.value.open();
    }

    if (
      _hasNannyApplication() === false &&
      popupPushToCreateApplicationRef.value
    ) {
      // open popup-push to Create application
      return popupPushToCreateApplicationRef.value.open();
    }

    if (canShowPopupCall.value && popupCallRef.value) {
      popupCallRef.value.open();
    }
  };

  const openGallery = (frameNum) => {
    if (galleryRef.value === null || isArchived.value) {
      return;
    }
    galleryRef.value.open(frameNum);
  };

  const _hasNannyApplication = () => {
    return (
      userStore.applications.nanny_id_populated?.meta.status === "published"
    );
  };

  return {
    offer,
    readyTextQuote,
    readyAdvantagesRequired,
    isContractor,
    isArchived,
    canShowPopupReply,
    canShowPopupCall,

    OFFER_ID,
    FROM_APPLICATION_ID,

    popupReplyRef,
    openPopupReply,

    galleryRef,
    openGallery,

    popupCallRef,
    openPopupCall,

    popupPushToRegistrationRef,
    popupPushToCreateApplicationRef,
  };
}
