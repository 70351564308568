<template>
  <div class="content" v-if="offer">
    <OfferNannyDetailsSectionPhotoMobile
      :photo-main="offer.details.photoMain"
      :default-color-num="offer.details.photoMainColorNum"
      :activeness="offer.meta.actuality"
      :is-archived="isArchived"
    />
    <OfferNannyDetailsSectionTitleMobile
      :title="offer.details.title"
      :geo-label="offer.details.location.label"
      class="content__section-title"
    />
    <OfferNannyDetailsSectionKidsMobile
      :kids="offer.details.kids"
      :is-special-care="offer.details.isSpecialCare"
    />
    <BlockFee
      :fee-hour="offer.details.rate.hour"
      :fee-month="offer.details.rate.month"
    />
    <BlockTextQoute :text="readyTextQuote" />
    <OfferNannyDetailsSectionPerksMobile
      :perks="offer.requirements.perks"
      :languages="offer.requirements.languages"
    />
    <OfferNannyDetailsSectionScheduleMobile
      :schedule-value="offer.schedule.value"
      :schedule-date-start-string="offer.schedule.dateStart.string"
      :schedule-date-start-days-before-start="
        offer.schedule.dateStart.daysLeftBeforeStart
      "
      :schedule-comment="offer.schedule.comment"
      :schedule-period="offer.schedule.period"
    />
    <OfferNannyDetailsSectionCareChildMobile
      :child-care="offer.requirements.responsibilities.careChild"
    />
    <OfferNannyDetailsSectionCareActivitiesMobile
      :activities="offer.requirements.responsibilities.activities"
    />
    <OfferNannyDetailsSectionCareHomeMobile
      :home-care="offer.requirements.responsibilities.careHome"
    />
    <OfferNannyDetailsSectionDescriptionMobile
      :description="offer.details.description"
    />

    <OfferNannyDetailsNavMobile v-if="isMobile" />
  </div>
</template>

<script>
import useOfferNannyDetails from "@/composables/useOfferNannyDetails";

import OfferNannyDetailsSectionPhotoMobile from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionPhotoMobile.vue";
import OfferNannyDetailsSectionTitleMobile from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionTitleMobile.vue";
import BlockFee from "@/components/BlockFee.vue";
import BlockTextQoute from "@/components/BlockTextQoute.vue";
import OfferNannyDetailsSectionKidsMobile from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionKidsMobile.vue";
import OfferNannyDetailsSectionPerksMobile from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionPerksMobile.vue";
import OfferNannyDetailsSectionScheduleMobile from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionScheduleMobile.vue";
import OfferNannyDetailsSectionCareChildMobile from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionCareChildMobile.vue";
import OfferNannyDetailsSectionCareActivitiesMobile from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionCareActivitiesMobile.vue";
import OfferNannyDetailsSectionCareHomeMobile from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionCareHomeMobile.vue";
import OfferNannyDetailsSectionDescriptionMobile from "@/components/Offer/Nanny/Details/OfferNannyDetailsSectionDescriptionMobile.vue";
import useIsMobile from "@/composables/useIsMobile";
import OfferNannyDetailsNavMobile from "@/components/Offer/Nanny/Details/NavMobile/OfferNannyDetailsNavMobile.vue";

export default {
  components: {
    OfferNannyDetailsSectionPhotoMobile,
    OfferNannyDetailsSectionTitleMobile,
    BlockFee,
    BlockTextQoute,
    OfferNannyDetailsSectionKidsMobile,
    OfferNannyDetailsSectionPerksMobile,
    OfferNannyDetailsSectionScheduleMobile,
    OfferNannyDetailsSectionCareChildMobile,
    OfferNannyDetailsSectionCareActivitiesMobile,
    OfferNannyDetailsSectionCareHomeMobile,
    OfferNannyDetailsSectionDescriptionMobile,
    OfferNannyDetailsNavMobile,
  },

  setup() {
    const { offer, readyTextQuote, isArchived } = useOfferNannyDetails();
    const { isMobile } = useIsMobile();

    return { offer, readyTextQuote, isMobile, isArchived };
  },
};
</script>

<style scoped>
.content {
  margin-bottom: 30px;

  display: flex;
  flex-flow: column;
  align-items: center;

  row-gap: 60px;
}
.content__section-title {
  margin: -30px;
}
</style>
